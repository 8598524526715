<template>
  <div class="inventoryValueReport container">

	<p class="h6 text-center text-primary"><b>Inventory Value Report</b></p>    
	<br>

	<CCard :color="'secondary'">
		<CCardBody>
			<form class="form-inline">
				<div class="form-group">
					<label class="font-weight-bold mr-1" for="reportDate">Date:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-2" 
							v-model="reportDate" name="reportDate"></datepicker>

					<CButton color="dark" v-on:click.prevent="serverCall('getValueSummary')">
						Fetch Data
					</CButton>
				</div>

				<div>
					<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
					<scale-loader :loading="loading"></scale-loader>
				</div>

			</form>	
		</CCardBody>
	</CCard>

	<p class="text-danger" align="left">{{alert}}</p>

	<div>


		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:row-style-class="rowStyleClassFn"
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table condensed"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field != 'location'">
					<span>{{props.formattedRow[props.column.field].toLocaleString('en-IN')}}</span> 
				</span>
				<span v-else>
					{{props.formattedRow[props.column.field]}}
				</span>
			</template>
			<div slot="table-actions">
				<CButton color="dark" class="mr-3" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
		</vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'inventoryValueReport',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,

			columns: [],
			rows: [],

			reportDate: null,
			reportName: 'Inventory Value',
			reportLongName: "",
			reportData: "",

			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(), // Disable all dates after specific date
			},

			alert: null,
			loading: false,
		
		};
	},

	computed: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		this.reportDate = new Date()
	},

	methods: {
		rowStyleClassFn(row) {
			if (row.color == 1){
				return 'blue'
			}
		},

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getValueSummary":
								self.getValueSummary(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Report Data for the given date.
        getValueSummary(authToken) {
            var self = this
			self.alert = null

            if (!this.reportDate){
                self.alert = "Report date not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Report Data..."

			var postJson = {
				'reportDate': this.getMidDay(this.reportDate),
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var reportDateStr = this.reportDate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetValueSummaryData/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getValueSummaryData - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						self.reportLongName = self.reportName + " on: " + reportDateStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},

		downloadReport() {
			if (this.reportData==[]){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},
		

	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.active {
		color : green;
	}
</style>

